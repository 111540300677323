@import 'styles/variables.scss';

.styles {
  :global {
    .header-main {
      height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: space-between;
      background: $primary;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      padding: 0 50px;
      position: fixed;
      top: 0;
      z-index: 1000;
      // overflow: hidden;
      img {
        width: auto;
        height: 32px;
        cursor: pointer;
      }
      .tools {
        display: flex;
        align-items: center;
        .sub-tab {
          height: 80px;
          // padding-top: 11px;
        }

        .tab {
          padding: 0px 20px;
          height: 80px;
          line-height: 80px;
          cursor: pointer;
          font-size: 20px;
          transition: all 0.3s;
          // border-radius: 25px;

          &:hover:not(.language) {
            background: #663751;
          }

          &.active {
            background: #663751;
            // color: $primary;
          }
        }
      }
      .language {
        position: relative;
        // width: 99px;
        padding: 16px 40px !important;
        height: 49px !important;
        line-height: 49px !important;
        border: 1px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 40px !important;
        border-radius: 25px;
        background: rgba(255, 255, 255, 0.2);
        font-size: 14px !important;

        &::before {
          content: '';
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-45%);
          width: 20px;
          height: 20px;
          background: url(~static/images/earth.png) no-repeat center center;
        }
        &::after {
          content: '▶';
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-45%) rotate(90deg);
          transform-origin: 6px center;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
        }
      }
    }

    @media (max-width: 768px) {
      .header-main {
        padding: 0 31px 0 25px;
        .icon-style {
          font-size: 25px;
        }
      }
    }
  }
}
