@import 'styles/variables.scss';
.main.ant-layout-content {
  min-height: calc(100vh - 70px);
}
#root .ant-layout {
  background-color: $bg-1;
}
.ant-layout.white {
  background-color: white;
}
.ant-layout.full {
  header {
    display: none;
  }
  .main.ant-layout-content {
    min-height: 100vh;
  }
}

.rounded-20px.rounded-20px {
  border-radius: 20px;
  width: 230px;
}

.menu-dropdown {
  background-color: #5C3049 !important;
  border-radius: 0px !important;
  box-shadow: none;

  & > ul {
    padding: 22px 0;

    & > li {
      width: 250px;
      padding: 0 27px;
      line-height: 30px;
    }
  }

  .MuiListItem-button {
    color: #fff;
    // transition: all 0.3s ease-in-out;
  }

  .MuiListItem-button:hover {
    background: transparent;
    font-weight: 600;
  }
}

.mobile-sub-menu {
  & > li {
    padding-left: 50px;
    color: #fff;
  }
}