@import '~antd/dist/antd.css';
@import 'styles/variables.scss';
body {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: $primary-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  overflow: auto !important;
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $grey;
  }
  @media screen and (max-width: 1300px) {
    min-width: $min-width;
  }
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
  // max-width: $max-width;
}

@font-face {
  font-family: Proxima Nova;
  src: url('styles/fonts/ProximaNovaReg.otf') format('opentype');
}
@font-face {
  font-family: Proxima Nova;
  src: url('styles/fonts/ProximaNovaReg.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: Proxima Nova;
  src: url('styles/fonts/Proxima Nova Sbold.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: Proxima Nova;
  src: url('styles/fonts/ProximaNovaBold.otf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: Proxima Nova semi;
  src: url('styles/fonts/Proxima Nova Sbold.otf') format('opentype');
}
@font-face {
  font-family: Proxima Nova bold;
  src: url('styles/fonts/ProximaNovaBold.otf') format('opentype');
}
@font-face {
  font-family: Canela;
  src: url('styles/fonts/Canela-Medium.otf') format('opentype');
}
@font-face {
  font-family: FontAwesome;
  src: url('styles/fonts/FontAwesome.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  src: url('styles/fonts/Inter.ttf') format('opentype');
}

@font-face {
  font-family: 'Cormorant';
  src: url('styles/fonts/CormorantGaramond-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Calibri;
  src: url('styles/fonts/calibri-regular.ttf') format('opentype');
}

@font-face {
  font-family: Garamond;
  src: url('styles/fonts/EBGaramond.ttf') format('opentype');
}

.grey {
  color: $grey;
}
.dark-grey {
  color: $dark-grey;
}
.grey-1 {
  color: $grey-1;
}
.grey-2 {
  color: $grey-2;
}
.grey-3 {
  color: $grey-3;
}
.grey-4 {
  color: $grey-4;
}
.grey-5 {
  color: $grey-5;
}

$spaceamounts: (
  0,
  4,
  5,
  6,
  8,
  10,
  12,
  15,
  16,
  17,
  18,
  20,
  24,
  25,
  28,
  30,
  32,
  34,
  35,
  40,
  45,
  48,
  50,
  60,
  -60,
  62,
  75,
  100,
  120,
  140,
  150,
  160,
  200
);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

$fontsizelist: (11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 28, 32, 35,36, 40, 42, 48, 60, 64);

@each $size in $fontsizelist {
  .font-#{$size} {
    font-size: #{$size}px;
  }
}
.font-12 {
  font-size: var(--font12);
}
$spanWidthList: (100, 150, 200, 300, 360);
@each $size in $spanWidthList {
  .span-#{$size} {
    max-width: #{$size}px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
}

$iconSizeList: (13, 14, 15, 16, 18, 20, 22, 24, 30);

@each $size in $iconSizeList {
  .icon-#{$size} {
    height: #{$size}px;
    width: #{$size}px;
  }
}

.mobile-drawer {
  .MuiDrawer-paper {
    top: 70px;
    height: calc(100% - 70px);
    width: 100%;
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0);
  }
  .tab-item {
    position: relative;
    padding: 0 20px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    box-shadow: inset 0 -1px 0 darken($primary, 10%);

    &.no-border {
      box-shadow: none;
    }

    &.active {
      background: rgba(255, 255, 255, 0.1);
    }

    &.language {
      padding-left: 50px !important;
      &::before {
        content: '';
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-45%);
        width: 20px;
        height: 20px;
        background: url(~static/images/earth.png) no-repeat center center;
      }
    }
  }
}
.popover-image {
  width: 250px;
}

@keyframes colorChange {
  0% {
    opacity: 1; // 起始颜色
  }
  50% {
    opacity: 0.1; // 中间颜色
  }
  100% {
    opacity: 1; // 回到起始颜色
  }
}

@keyframes mask-slide-in {
  0% {
    // transform: translateY(100%);
    opacity: 0;
  }
  100% {
    // transform: translateY(0%);
    opacity: 1;
  }
}
