@import 'styles/variables.scss';

.styles {
  :global {
    .link {
      display: flex;
      justify-content: space-around;
      padding-top: 52px;
      background: rgba(45, 47, 71, 1);

      .link-wrapper {
        width: 1284px;
        margin: 0 auto;

        .contact-us {
          padding: 0 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .contact-us-left {
            .contact-us-text {
              margin: 20px 0;
              width: 408px;
              color: #fff;
            }

            .links {
              display: flex;
              column-gap: 20px;
              & > a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                margin-bottom: 10px;
                width: 32px;
                height: 32px;
                background: rgba(85, 87, 117, 1);
                border-radius: 16px;
                & > img {

                }
              }
            }
          }

          .menu {
            height: 100px;
            display: grid;
            grid-template-columns: 1fr 1fr;

            & > a {
              color: #fff;

              &:hover {
                color: $primary;
              }
              &.active {
                color: $primary;
              }
            }
          }
        }

        .copyright {
          margin-top: 32px;
          border-top: rgba(255, 255, 255, 0.30) 1px solid;
          height: 97px;
          line-height: 97px;
          color: #fff;
          text-align: center;
          font-size: 14px;
        }
      }



      .follow-us,
      .official {
        margin-right: 29px;
        p {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */
          text-align: left;
          color: #000000;
        }
        .follow-item {
          display: flex;
          img {
            width: 36px;
            height: 36px;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 18px;
            }
          }
        }
      }
      .follow-us,
      .official,
      .Policy {
        // width: 336px;
        // height: 150px;
        a {
          display: block;
          text-decoration: underline;
        }
        p,
        a {
          color: #000000;
          margin-bottom: 5px;
        }
      }
      .official {
        text-align: center;
        p {
          text-align: center;
        }
        &-item {
          margin-left: 5px;
          img {        
            width: 48px;
            height: 36px;
            &:not(:first-child) {
              margin-left: 30px;
            }
          }
        }
      }
      .Policy {
        text-align: right;
      }
    }
    @media (max-width: 768px) {


      .link {
        .link-wrapper {
          width: 100%;

          .contact-us {
            display: block;

            .contact-us-left {
              .contact-us-text {
                width: 100%;
              }
            }

            .menu {
              margin-top: 30px;

              & > a {
                text-align: left;
              }
            }
          }

          .copyright {
            margin-top: 0;
          }
        }
        .follow-us {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 0;
          img {
            width: 48px;
            height: 36px;
            &:not(:last-child) {
              margin-right: 18px;
            }
          }
        }
        .official {
          margin-top: 41px;
          margin-right: 0;
        }
        .Policy {
          margin-top: 40px;
          text-align: center;
        }
      }
    }
  }
}
